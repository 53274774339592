$accent-colour: #53b000;

// Used to achieve AA contrast compliance
$button-text-colour: black;

$highlighted-background-colour: #2e3031;
$front-background-colour: #212222;
$back-background-colour: #191919;

$link-colour: #53b000;

$heading-text-colour: #c1c1c1;
$header-background-colour: $front-background-colour;
$card-content-background-colour: $front-background-colour;
$sidebar-background-colour: black;

$body-text-colour: #a4b1b2;
$light-text-colour: darken($body-text-colour, 15);
$read-text-colour: darken($body-text-colour, 10);

$light-border-colour: #c1c1c1;

@use '~@cognitranlimited/itis-web/dist/styles' with (
    $DocumentShareLinkUserSearch-role-or-group-text-colour:white,
    $colour-accent: $accent-colour,
    $body-background: $back-background-colour,
    $body-font-size: 16px,
    $body-text-colour: $body-text-colour,
    $light-border-colour: $light-border-colour,
    $Link-text-colour: $link-colour,
    $heading-font-weight: 600,
    $heading-text-colour: $heading-text-colour,
    $well-background: #1d1e1e,
    $input-background: $highlighted-background-colour,
    $app-background: $back-background-colour,
    $light-text-colour: $light-text-colour,
    $success-text-colour: #00a016,
    $colour-danger: #ff3b37,
    $Sidebar-background: $sidebar-background-colour,
    $Sidebar-light-text-colour: $body-text-colour,
    $IconButton-icon-colour: $heading-text-colour,
    $IconButton-icon-colour-disabled: darken($heading-text-colour, 50),
    $Button-text-colour: $button-text-colour,
    $Button-text-colour-disabled: $button-text-colour,
    $Button-background-disabled: #757575,
    $Panel-header-text-colour: $heading-text-colour,
    $Panel-background: $front-background-colour,
    $Panel-box-shadow: 0px 0px 2px rgba(117, 117, 117, 0.8),
    $Panel-info-background: #393121,
    $Header-icon-colour: $heading-text-colour,
    $Header-icon-font-size: 16px,
    $Header-background: $header-background-colour,
    $ContentHeader-title-font-size: 24px,
    $VerticalTabBar-font-size: 18px,
    $Link-title-text-colour: $accent-colour,
    $VehicleHistoryPopover-header-font-size: 18px,
    $VehicleHistoryList-details-font-size: 14px,
    $NewLookPanel-background: $highlighted-background-colour,
    $SidebarToggle-accented-text-colour: $back-background-colour,
    $SidebarToggle-accented-background: lighten($accent-colour, 2),
    $SidebarToggle-accented-background-hover: lighten($accent-colour, 4),
    $SidebarToggle-accented-background-active: lighten($accent-colour, 6),
    $SearchBox-background: $highlighted-background-colour,
    $CollapsedSidebar-handle-colour: $back-background-colour,
    $DocumentLabel-text-colour: $button-text-colour,
    $MessageTableRow-read-background: darken($front-background-colour, 2),
    $HeaderSearch-background: $highlighted-background-colour,
    $LanguageSelectItem-selected-background: #478411,
    $small-font-size: 12px,
    $Sidebar-small-font-size: 12px,
    $CollapsedSidebar-background: $accent-colour,
    $input-border: 1px solid $highlighted-background-colour,
    $input-background-disabled: $highlighted-background-colour,
    $document-background: white,
    $ExpandedSidebar-border-right: 8px solid $accent-colour,
    $SidebarDocumentGroup-title-font-weight: inherit,
    $Table-header-background-hover: unset,
    $Table-header-background-active: unset,
    $VehicleTabs-text-colour: $heading-text-colour,
    $VehicleTabs-text-colour-hover: lighten($heading-text-colour, 10),
    $VehicleTabs-text-colour-selected: $accent-colour,
    $VehicleTabs-font-weight: bold,
    $Table-tick-box-ticked-colour: $accent-colour,
    $Table-tick-box-unticked-colour: $accent-colour,
    $Table-table-row-border-bottom: 1px solid rgba(117, 117, 117, 0.4),
    $TickBox-ticked-colour: $accent-colour,
    $TickBox-unticked-colour: $accent-colour,
    $ImportantDocumentItem-viewed-text-colour: $read-text-colour,
    $DocumentLabel-text-colour-new: $button-text-colour,
    $DocumentLabel-background-new: $accent-colour,
);

@use '~@cognitranlimited/itis-web/dist/styles/campaigns';

/**
 * Temporary overrides (these should have a corresponding core case to avoid accumulating styling overrides)
**/

.SidebarDocumentGroup-title {
    font-weight: inherit;
}

.Table-sortableHeader:hover,
.Table-sortableHeader:active {
    background-color: unset;
}

// Material UI overrides
// center logo
.ExpandedSidebar_Logo {
    text-align: center;
}

// Selects
.MuiNativeSelect-icon {
    color: $body-text-colour !important;
}

.MuiNativeSelect-select:not([multiple]) {
    option,
    optgroup {
        background-color: $highlighted-background-colour !important;
    }
}

// AccountMenu
.MuiPaper-root {
    background: $back-background-colour !important;
    color: $body-text-colour !important;
}

// AccountMenuItem
.MuiListItemIcon-root {
    color: $body-text-colour !important;
}

.VehicleTabs {
    .MuiTab-textColorPrimary {
        color: $heading-text-colour !important;

        &:hover {
            color: lighten($heading-text-colour, 10) !important;
        }

        &.Mui-selected {
            color: $accent-colour !important;
            font-weight: bold;
        }
    }
}

// apply changes for campaigns vehicle tab that has campaigns badge but its not zero
.VehicleTabs a[href$='/campaigns']:has(div.CampaignsBadge):not(:has(div.CampaignsBadge-zero))  {
    padding-left: 0.25rem;
    background-color: yellow;
    &::after {
        margin-top: 0.2rem;
        padding: 0.25rem;
        font-size: 1.6rem;
        content: '⚠';
        color: red;
    }
    &.MuiTab-textColorPrimary {
        font-weight: bold;
        color: red !important;
    }
 }

// I can't use $MessageTableRow-read-text-colour, because it also changes text in from and date columns
// It also doesn't cover hover effects
// TODO allow further customization for MessageTableRow state
.MessageTableRow-read {
    color: $read-text-colour;
    a {
        color: $read-text-colour;
        &:hover {
            color: darken($body-text-colour, 4);
        }
    }

}

.MessageTableRow {
    .MuiCheckbox-root > svg {
        color: $body-text-colour;
    }
}

.MessageTable thead {
    .MuiCheckbox-root > svg {
        color: $body-text-colour;
    }
}

.MuiAutocomplete-noOptions {
    color: white !important;
    background-color: $back-background-colour !important;
}

.MuiOutlinedInput-root.DocumentSearchFilter, .DocumentSearchFilter .MuiSvgIcon-root {
    color: $heading-text-colour!important;
}

.MuiDivider-root {
    border-color: rgba(117, 117, 117, 0.4)!important;
}

.SidebarDocumentGroups-loading {
    .MuiSkeleton-root {
        background-color: darken($body-text-colour, 30);
    }
}

// TODO remove when backend is 39.0.0 or higher
.DocumentInfoDialogue-actions button {
    display: none;
}

.LegalRepresentativeForm .MuiChip-root, 
.LegalRepresentativeForm .MuiSvgIcon-root {
    color: #c1c1c1 !important;
}

.LegalRepresentativeForm .MuiChip-root {
    background-color: darken($accent-colour, 15);
}

.LegalEmployeesPanel-employeesTable .MuiChip-root {
    color: #c1c1c1 !important;
    background-color: darken($accent-colour, 15);
}